.icon, .ssi-modalOuter.ssi-fullScreen .ssi-modalWindow .ssi-displayIcon, .ssi-topIcons .ssi-displayIcon, .ssi-topIcons .ssi-closeIcon, .ssi-modalNavigation .ssi-modalPrev span, .ssi-modalNavigation .ssi-modalNext span, .notify.ssi-success .ssi-modalTitle span.success, .notify.ssi-info .ssi-modalTitle span.success, .notify.ssi-warning .ssi-modalTitle span.success, .notify.ssi-error .ssi-modalTitle span.success, .notify.ssi-info .ssi-modalTitle span.info, .notify.ssi-warning .ssi-modalTitle span.warning, .notify.ssi-error .ssi-modalTitle span.error {
  background-image: url("images/sprite.png");
}

body.ssi-modalOpen {
  overflow: hidden;
}

.ssi-backdrop {
  position: fixed;
  overflow: hidden;
  z-index: 1500;
  top: 0;
  left: 0;
  background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#B3000000', endColorstr='#B3000000');
  /* IE */
}

.ssi-modalOuter {
  position: fixed;
  z-index: 1500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.ssi-modalOuter.ssi-fullScreen .ssi-modalWrapper {
  width: 100% !important;
  margin: 0;
  border-radius: 0;
  height: 100%;
}

.ssi-modalOuter.ssi-fullScreen .ssi-modalWindow {
  overflow: auto;
  border-radius: 0;
  min-height: 100%;
}

.ssi-modalOuter.ssi-fullScreen .ssi-modalWindow .ssi-modalContent {
  padding-bottom: 0;
}

.ssi-modalOuter.ssi-fullScreen .ssi-modalWindow .ssi-buttons {
  position: fixed;
  width: 100%;
}

.ssi-modalOuter.ssi-fullScreen .ssi-modalWindow .ssi-displayIcon {
  background-position: 0px -120px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 24px;
  height: 24px;
}

.ssi-modalOuter.ssi-fullScreen .ssi-modalWindow .ssi-displayIcon:hover {
  background-position: 0px -96px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 24px;
  height: 24px;
}

.ssi-center {
  display: table;
}

.ssi-center .ssi-modalWrapper {
  display: table-cell;
  vertical-align: middle;
}

.ssi-center .ssi-modalWindow {
  margin-left: auto;
  margin-right: auto;
}

.ssi-modalWrapper {
  position: relative;
  margin: 30px auto 20px;
  z-index: 1501;
}

.ssi-modalWrapper.full {
  width: 100%;
}

.ssi-modalWrapper.auto, .ssi-modalWrapper .ssi-modalWindow.auto {
  display: table;
  width: auto;
}

.ssi-modalWrapper.large, .ssi-modalWrapper .ssi-modalWindow.large {
  width: 80%;
}

@media only screen and (max-width: 900px) {
  .ssi-modalWrapper.large, .ssi-modalWrapper .ssi-modalWindow.large {
    width: 85%;
  }
}

@media only screen and (max-width: 600px) {
  .ssi-modalWrapper.large, .ssi-modalWrapper .ssi-modalWindow.large {
    width: 95%;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-modalWrapper.large, .ssi-modalWrapper .ssi-modalWindow.large {
    width: 98%;
  }
  .ssi-modalWrapper.large .ssi-modalTitle, .ssi-modalWrapper .ssi-modalWindow.large .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-modalWrapper.mediumToLarge, .ssi-modalWrapper .ssi-modalWindow.mediumToLarge {
  width: 70%;
}

@media only screen and (max-width: 900px) {
  .ssi-modalWrapper.mediumToLarge, .ssi-modalWrapper .ssi-modalWindow.mediumToLarge {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .ssi-modalWrapper.mediumToLarge, .ssi-modalWrapper .ssi-modalWindow.mediumToLarge {
    width: 90%;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-modalWrapper.mediumToLarge, .ssi-modalWrapper .ssi-modalWindow.mediumToLarge {
    width: 95%;
  }
  .ssi-modalWrapper.mediumToLarge .ssi-modalTitle, .ssi-modalWrapper .ssi-modalWindow.mediumToLarge .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-modalWrapper.medium, .ssi-modalWrapper .ssi-modalWindow.medium {
  width: 62%;
}

@media only screen and (max-width: 900px) {
  .ssi-modalWrapper.medium, .ssi-modalWrapper .ssi-modalWindow.medium {
    width: 72%;
  }
}

@media only screen and (max-width: 600px) {
  .ssi-modalWrapper.medium, .ssi-modalWrapper .ssi-modalWindow.medium {
    width: 82%;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-modalWrapper.medium, .ssi-modalWrapper .ssi-modalWindow.medium {
    width: 95%;
  }
  .ssi-modalWrapper.medium .ssi-modalTitle, .ssi-modalWrapper .ssi-modalWindow.medium .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-modalWrapper.smallToMedium, .ssi-modalWrapper .ssi-modalWindow.smallToMedium {
  width: 50%;
}

@media only screen and (max-width: 900px) {
  .ssi-modalWrapper.smallToMedium, .ssi-modalWrapper .ssi-modalWindow.smallToMedium {
    width: 70%;
  }
}

@media only screen and (max-width: 600px) {
  .ssi-modalWrapper.smallToMedium, .ssi-modalWrapper .ssi-modalWindow.smallToMedium {
    width: 80%;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-modalWrapper.smallToMedium, .ssi-modalWrapper .ssi-modalWindow.smallToMedium {
    width: 90%;
  }
  .ssi-modalWrapper.smallToMedium .ssi-modalTitle, .ssi-modalWrapper .ssi-modalWindow.smallToMedium .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-modalWrapper.small, .ssi-modalWrapper .ssi-modalWindow.small {
  width: 45%;
}

@media only screen and (max-width: 900px) {
  .ssi-modalWrapper.small, .ssi-modalWrapper .ssi-modalWindow.small {
    width: 65%;
  }
}

@media only screen and (max-width: 600px) {
  .ssi-modalWrapper.small, .ssi-modalWrapper .ssi-modalWindow.small {
    width: 75%;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-modalWrapper.small, .ssi-modalWrapper .ssi-modalWindow.small {
    width: 85%;
  }
  .ssi-modalWrapper.small .ssi-modalTitle, .ssi-modalWrapper .ssi-modalWindow.small .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-modalWrapper.dialog, .ssi-modalWrapper .ssi-modalWindow.dialog {
  width: 30%;
}

@media only screen and (max-width: 900px) {
  .ssi-modalWrapper.dialog, .ssi-modalWrapper .ssi-modalWindow.dialog {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .ssi-modalWrapper.dialog, .ssi-modalWrapper .ssi-modalWindow.dialog {
    width: 60%;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-modalWrapper.dialog, .ssi-modalWrapper .ssi-modalWindow.dialog {
    width: 80%;
  }
  .ssi-modalWrapper.dialog .ssi-modalTitle, .ssi-modalWrapper .ssi-modalWindow.dialog .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-modalWindow {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #c3c3c3;
  position: relative;
  border-radius: 8px;
  padding: 18px 6px 5px 6px;
}

.ssi-topIcons {
  padding-left: 5px;
  font-weight: 600;
  position: absolute;
  top: 0;
  line-height: 25px;
  right: 0;
  z-index: 1;
}

.ssi-topIcons a {
  float: left;
  color: #8c8c8c;
  font-size: 30px;
  text-decoration: none;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-color: inherit;
}

.ssi-topIcons a:hover {
  color: #265067;
}

.ssi-topIcons .ssi-displayIcon {
  background-position: 0px 0px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 24px;
  height: 24px;
}

.ssi-topIcons .ssi-displayIcon:hover {
  background-position: 0px -144px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 24px;
  height: 24px;
}

.ssi-topIcons .ssi-closeIcon {
  background-position: 0px -72px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 24px;
  height: 24px;
}

.ssi-topIcons .ssi-closeIcon:hover {
  background-position: 0px -24px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 24px;
  height: 24px;
}

.ssi-modalTitle {
  padding: 0 0 5px 15px;
  font: italic 600 25px Georgia, serif;
  border-bottom: 1px solid #dddddd;
}

.ssi-modalTitle .ssi-displayTime {
  font-size: 18px;
}

.ssi-modalContent {
  min-height: 50px;
  margin-top: 0px;
  padding: 5px 15px 10px 15px;
  z-index: 1501;
  position: relative;
}

.ssi-modalContent:after {
  content: " ";
  display: table;
  clear: both;
}

.ssi-modalContent::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ssi-modalContent::-webkit-scrollbar:vertical {
  width: 15px;
}

.ssi-modalContent::-webkit-scrollbar:horizontal {
  height: 12px;
}

.ssi-modalContent::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.ssi-modalContent::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.ssi-modalContent.ssi-overflow {
  overflow: auto;
}

.ssi-buttons {
  position: relative;
  bottom: 0;
  padding: 5px 5px 1px 5px;
  border-top: 1px solid #dddddd;
}

.ssi-buttons:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.ssi-buttons .ssi-leftButtons {
  float: left;
}

.ssi-buttons .ssi-rightButtons {
  float: right;
}

.ssi-buttons .ssi-modalBtn {
  margin: 2px;
}

.ssi-buttons .ssi-countDown {
  color: #d1d1d1;
  font-size: 12px;
  margin-left: 5px;
}

.ssi-modalPositioned {
  overflow: visible;
  height: 0;
}

.ssi-modalPositioned.right .ssi-modalWrapper {
  float: right;
}

.ssi-modalPositioned.right .ssi-modalWrapper .ssi-modalWindow {
  float: right;
}

.ssi-modalPositioned.left .ssi-modalWrapper {
  float: left;
}

.ssi-modalPositioned.left .ssi-modalWrapper:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.ssi-modalPositioned.left .ssi-modalWindow {
  float: left;
}

.ssi-modalPositioned.bottom {
  top: auto;
  height: auto;
  bottom: 0;
}

.ssi-modalPositioned.bottom .ssi-modalWrapper {
  height: auto;
}

.ssi-modalPositioned.top {
  top: 0;
}

.ssi-modalPositioned.center .ssi-modalWrapper {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ssi-modalPositioned.center .ssi-modalWrapper .ssi-modalWindow {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ssi-modalPositioned .ssi-modalWrapper {
  margin: 15px 3px;
  padding: 0;
  height: 0;
}

.ssi-modalPositioned .ssi-modalWrapper .ssi-modalWindow {
  margin: 10px 0;
  clear: both;
  z-index: 1500;
}

.ssi-modalPositioned.ssi-stack {
  pointer-events: none;
  z-index: 3000;
}

.ssi-modalPositioned.ssi-stack.center .ssi-modalWrapper .ssi-modalWindow {
  margin: 4px;
}

.ssi-modalPositioned.ssi-stack .ssi-modalWrapper {
  width: 100%;
  margin: 0;
}

.ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow:first-child {
  margin-top: 10px;
}

.ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow {
  pointer-events: auto;
  margin: 2px 7px;
  box-shadow: -1px 1px 28px -2px #a196a1;
}

.ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.ssi-smoothSlide {
  visibility: hidden;
  display: block !important;
}

.ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.full {
  width: 99.9%;
  margin: 1px 1px;
}

@media only screen and (max-width: 450px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.full {
    width: 99.5%;
  }
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.full .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.large {
  width: 800px;
}

@media only screen and (max-width: 900px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.large {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.large {
    width: 85%;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.large {
    width: 99.5%;
    margin: 1px 1px;
  }
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.large .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.mediumToLarge {
  width: 700px;
}

@media only screen and (max-width: 900px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.mediumToLarge {
    width: 85%;
  }
}

@media only screen and (max-width: 600px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.mediumToLarge {
    width: 75%;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.mediumToLarge {
    width: 80%;
  }
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.mediumToLarge .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.medium {
  width: 600px;
}

@media only screen and (max-width: 900px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.medium {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.medium {
    width: 75%;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.medium {
    width: 80%;
  }
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.medium .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.smallToMedium {
  width: 500px;
}

@media only screen and (max-width: 600px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.smallToMedium {
    width: 60%;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.smallToMedium {
    width: 55%;
  }
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.smallToMedium .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.small {
  width: 400px;
}

@media only screen and (max-width: 600px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.small {
    width: 60%;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.small {
    width: 55%;
  }
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.small .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.dialog {
  width: 300px;
}

@media only screen and (max-width: 600px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.dialog {
    width: 45%;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.dialog {
    width: 55%;
  }
  .ssi-modalPositioned.ssi-stack .ssi-modalWrapper .ssi-modalWindow.dialog .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-hover {
  cursor: pointer;
}

.ssi-hover:hover {
  box-shadow: -1px 1px 17px 0px #757175;
}

.ssi-hidden {
  display: none;
}

.ssi-borderOut {
  border: none !important;
}

.anim {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes ssi-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ssi-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ssi-fadeIn {
  -webkit-animation-name: ssi-fadeIn;
  animation-name: ssi-fadeIn;
}

@-webkit-keyframes ssi-fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ssi-fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ssi-fadeOut {
  -webkit-animation-name: ssi-fadeOut;
  animation-name: ssi-fadeOut;
}

/*------------------------Start of imgBox plugin------------------------------*/
.ssi-backdrop.imgBox {
  background: rgba(0, 0, 0, 0.87);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#CC000000', endColorstr='#CC000000');
  /* IE */
}

.imgBox {
  overflow: auto;
}

.imgBox.ssi-center .ssi-modalWindow {
  width: 300px;
}

.imgBox .ssi-modalWrapper {
  margin-top: 55px;
  z-index: 11111;
  width: 300px;
}

.imgBox .ssi-modalWrapper .ssi-topIcons {
  margin-right: -15px;
  margin-top: -12px;
  z-index: 11111;
}

.imgBox .ssi-modalWrapper .ssi-topIcons a {
  background-color: #5c5c5e;
  color: #fff;
  width: 24px;
  height: 24px;
  line-height: 0;
  border-radius: 30px;
}

.imgBox .ssi-modalWrapper .ssi-topIcons a:hover {
  color: #e9e9e9;
}

.imgBox .ssi-modalWrapper .ssi-topIcons .ssi-closeIcon {
  background-position: 0px -48px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 24px;
  height: 24px;
}

.imgBox .ssi-modalWrapper .ssi-topIcons .ssi-closeIcon:hover {
  background-position: 0px -48px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 24px;
  height: 24px;
}

.imgBox .ssi-modalWrapper .ssi-topIcons.inTitle {
  margin-top: 25px;
}

@media only screen and (max-width: 450px) {
  .imgBox .ssi-modalWrapper .ssi-topIcons.inTitle {
    margin-top: 15px;
  }
  .imgBox .ssi-modalWrapper .ssi-topIcons.inTitle .ssi-modalTitle {
    font-size: 18px;
  }
}

.imgBox .ssi-modalWrapper.ssi-imgBorder .ssi-modalContent {
  --webkit-box-shadow: 0px 0px 31px -1px rgba(245, 245, 245, 0.3);
  box-shadow: 0px 0px 31px -1px rgba(245, 245, 245, 0.3);
  border: 1px solid white;
}

.imgBox .ssi-modalWrapper.ssi-overHeight, .imgBox .ssi-modalWrapper.ssi-overHeight .ssi-modalWindow {
  -webkit-transition: height .6s, width .6s;
  transition: height .6s, width .6s;
}

.imgBox .ssi-modalWrapper .ssi-modalWindow {
  border: none;
  border-radius: 2px;
  padding: 0;
  background: none;
}

.imgBox .ssi-modalWrapper .ssi-modalWindow .ssi-modalTitle {
  padding-left: 0;
  text-align: center;
  font-weight: 300;
  color: white !important;
  border-bottom: none;
}

@media only screen and (max-width: 450px) {
  .imgBox .ssi-modalWrapper .ssi-modalWindow .ssi-modalTitle {
    font-size: 15px;
  }
  .imgBox .ssi-modalWrapper .ssi-modalWindow .ssi-modalTitle .ssi-modalTitle {
    font-size: 18px;
  }
}

.imgBox .ssi-modalWrapper .ssi-modalWindow .ssi-buttons {
  border: none;
}

.imgBox .ssi-modalWrapper .ssi-modalWindow .ssi-modalContent {
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: 0;
  background: none;
  margin: 0 auto;
  padding: 0;
  height: 300px;
}

.imgBox .ssi-modalWrapper .ssi-modalWindow .ssi-modalContent h3, .imgBox .ssi-modalWrapper .ssi-modalWindow .ssi-modalContent h4 {
  color: #5e5e5e;
}

.imgBox .ssi-modalWrapper .ssi-modalWindow .ssi-modalContent .ssi-modalImg {
  width: 100%;
}

.imgBox .ssi-modalWrapper .ssi-modalWindow .ssi-modalContent .ssi-imgButtons {
  position: absolute;
  left: 0;
  bottom: auto;
  width: 100%;
  z-index: 101000001;
  padding: 8px;
}

.imgBox .ssi-modalWrapper .ssi-modalWindow .ssi-modalContent .ssi-imgButtons .ssi-leftButtons {
  float: left;
}

.imgBox .ssi-modalWrapper .ssi-modalWindow .ssi-modalContent .ssi-imgButtons .ssi-rightButtons {
  float: right;
}

.imgBox .ssi-modalWrapper .ssi-modalWindow .ssi-modalContent .ssi-imgButtons .ssi-imgBtn {
  margin: 2px;
  padding: 3px;
}

.ssi-modalNavigation {
  position: fixed;
  top: 50%;
  z-index: 1500;
  margin-top: -150px;
  left: 0;
  height: 300px;
  width: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
}

@media only screen and (max-width: 900px) {
  .ssi-modalNavigation {
    height: 200px;
    margin-top: -100px;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-modalNavigation {
    height: 100px;
    margin-top: -50px;
  }
  .ssi-modalNavigation .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-modalNavigation .ssi-modalPrev, .ssi-modalNavigation .ssi-modalNext {
  top: 0;
  outline: 0;
  height: 100%;
  cursor: pointer;
}

.ssi-modalNavigation .ssi-modalPrev span, .ssi-modalNavigation .ssi-modalNext span {
  position: relative;
  top: 40%;
}

.ssi-modalNavigation .ssi-modalPrev {
  width: 40%;
  left: 0;
  float: left;
}

.ssi-modalNavigation .ssi-modalPrev span {
  background-position: 0px -356px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 56px;
  height: 56px;
}

.ssi-modalNavigation .ssi-modalPrev:hover span {
  background-position: 0px -300px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 56px;
  height: 56px;
}

.ssi-modalNavigation .ssi-modalNext {
  width: 60%;
  float: right;
  right: 0;
}

.ssi-modalNavigation .ssi-modalNext span {
  float: right;
  background-position: 0px -468px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 56px;
  height: 56px;
}

.ssi-modalNavigation .ssi-modalNext:hover span {
  background-position: 0px -412px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 56px;
  height: 56px;
}

.imgBox .ssi-modalWrapper.ssi-iframe {
  width: 50%;
  height: 55%;
}

@media only screen and (max-width: 900px) {
  .imgBox .ssi-modalWrapper.ssi-iframe {
    padding: 10px;
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .imgBox .ssi-modalWrapper.ssi-iframe {
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .imgBox .ssi-modalWrapper.ssi-iframe {
    padding: 0;
    width: 100%;
  }
  .imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalTitle {
    font-size: 18px;
  }
}

.imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow {
  height: 100%;
}

.imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow .ssi-topIcons {
  margin-right: 8%;
  z-index: 2001;
}

.imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow .ssi-imgButtons {
  width: 0%;
}

@media only screen and (max-width: 900px) {
  .imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow {
    padding: 10px;
    width: 90%;
  }
  .imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow .ssi-topIcons {
    margin-top: 0;
    margin-right: 9%;
  }
  .imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow .ssi-topIcons.inTitle {
    margin-top: 35px;
  }
}

@media only screen and (max-width: 600px) {
  .imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow {
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow {
    padding: 0;
    width: 100%;
    max-height: 400px;
  }
  .imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow .ssi-modalTitle {
    font-size: 18px;
  }
  .imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow .ssi-modalContent .ssi-modalNext span {
    background-position-x: 15px;
  }
  .imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow .ssi-modalContent .ssi-modalNext:hover span {
    background-position-x: 15px;
  }
  .imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow .ssi-modalContent .ssi-modalPrev span {
    background-position-x: -15px;
  }
  .imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow .ssi-modalContent .ssi-modalPrev:hover span {
    background-position-x: -15px;
  }
  .imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow .ssi-topIcons {
    margin-top: -10px;
    margin-right: 5%;
  }
  .imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalWindow .ssi-topIcons.inTitle {
    margin-top: 15px;
  }
}

.imgBox .ssi-modalWrapper.ssi-iframe .ssi-modalContent {
  width: 100%;
  height: 90% !important;
}

.imgBox .ssi-modalWrapper.ssi-iframe iframe {
  position: relative;
  z-index: 2000;
  width: 80%;
  height: 100%;
}

.ssi-center.imgBox .ssi-iframe {
  width: 100%;
  height: 100%;
}

.ssi-center.imgBox .ssi-iframe .ssi-modalWindow {
  width: 60%;
  height: 55%;
}

@media only screen and (max-width: 900px) {
  .ssi-center.imgBox .ssi-iframe .ssi-modalWindow {
    padding: 10px;
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .ssi-center.imgBox .ssi-iframe .ssi-modalWindow {
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .ssi-center.imgBox .ssi-iframe .ssi-modalWindow {
    width: 100%;
    max-height: 400px;
    padding: 0;
  }
  .ssi-center.imgBox .ssi-iframe .ssi-modalWindow .ssi-modalTitle {
    font-size: 18px;
  }
}

.ssi-center.imgBox .ssi-iframe .ssi-modalContent {
  width: 100%;
  height: 90%;
}

.ssi-center.imgBox .ssi-iframe iframe {
  position: relative;
  z-index: 2000;
  width: 80%;
  height: 100%;
}

.ssi-loader {
  position: relative;
  margin: auto auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 32px;
  height: 32px;
  background: url("images/loader.gif") no-repeat;
}

.ssi-navFadeOut {
  opacity: 0 !important;
}

.ssi-navFadeIn {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
  filter: alpha(opacity=100) !important;
  opacity: 1 !important;
}

.ssi-navFade {
  -webkit-transition: opacity .4s;
  transition: opacity .4s;
}

/*------------------------End of imgBox plugin------------------------------*/
/*------------------------Start of notify plugin------------------------------*/
.notify.ssi-modalWindow {
  padding-top: 12px;
}

.notify .ssi-icon {
  max-width: 30px;
  max-height: 30px;
  float: left;
  clear: left;
}

.notify .ssi-modalContent {
  max-height: 150px;
  overflow: auto;
  padding: 5px 15px;
  margin-top: 0;
  min-height: 30px;
}

.notify .ssi-modalContent::-webkit-scrollbar:vertical {
  width: 6px;
}

.notify .ssi-modalContent::-webkit-scrollbar:horizontal {
  height: 6px;
}

.notify .ssi-modalTitle {
  line-height: 32px;
  padding-bottom: 5px;
}

.notify .ssi-modalTitle:after {
  content: " ";
  display: block;
  clear: both;
}

.notify.ssi-success, .notify.ssi-info, .notify.ssi-warning, .notify.ssi-error {
  padding: 3px 5px;
  margin: 2px 0;
  font: 500 18px "Times New Roman";
  color: #FFFFFF;
  background-color: #2ea53a;
}

.notify.ssi-success .ssi-modalTitle, .notify.ssi-info .ssi-modalTitle, .notify.ssi-warning .ssi-modalTitle, .notify.ssi-error .ssi-modalTitle {
  text-transform: capitalize;
  padding-left: 5px;
}

.notify.ssi-success .ssi-modalTitle span.success, .notify.ssi-info .ssi-modalTitle span.success, .notify.ssi-warning .ssi-modalTitle span.success, .notify.ssi-error .ssi-modalTitle span.success {
  background-position: 0px -234px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 33px;
  height: 33px;
}

.notify.ssi-success .ssi-modalContent::-webkit-scrollbar-thumb, .notify.ssi-info .ssi-modalContent::-webkit-scrollbar-thumb, .notify.ssi-warning .ssi-modalContent::-webkit-scrollbar-thumb, .notify.ssi-error .ssi-modalContent::-webkit-scrollbar-thumb {
  background-color: #3c7e28;
}

@media only screen and (max-width: 900px) {
  .notify.ssi-success, .notify.ssi-info, .notify.ssi-warning, .notify.ssi-error {
    font-size: 15px;
  }
  .notify.ssi-success .ssi-modalTitle, .notify.ssi-info .ssi-modalTitle, .notify.ssi-warning .ssi-modalTitle, .notify.ssi-error .ssi-modalTitle {
    padding-bottom: 1px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .notify.ssi-success, .notify.ssi-info, .notify.ssi-warning, .notify.ssi-error {
    width: 45%;
  }
}

@media only screen and (max-width: 450px) {
  .notify.ssi-success, .notify.ssi-info, .notify.ssi-warning, .notify.ssi-error {
    font-size: 12px;
  }
  .notify.ssi-success .ssi-modalTitle, .notify.ssi-info .ssi-modalTitle, .notify.ssi-warning .ssi-modalTitle, .notify.ssi-error .ssi-modalTitle {
    font-size: 18px;
  }
}

.notify.ssi-success:hover, .notify.ssi-info:hover, .notify.ssi-warning:hover, .notify.ssi-error:hover {
  background-color: #2c9738;
}

.notify.ssi-info {
  background-color: #006cbc;
}

.notify.ssi-info .ssi-modalTitle span.info {
  background-position: 0px -201px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 33px;
  height: 33px;
}

.notify.ssi-info .ssi-modalContent::-webkit-scrollbar-thumb {
  background-color: #5d6ad6;
}

.notify.ssi-info:hover {
  background-color: #00529d;
}

.notify.ssi-warning {
  background-color: #db9100;
}

.notify.ssi-warning .ssi-modalTitle span.warning {
  background-position: 0px -267px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 33px;
  height: 33px;
}

.notify.ssi-warning .ssi-modalContent::-webkit-scrollbar-thumb {
  background-color: #b57300;
}

.notify.ssi-warning:hover {
  background-color: #c68200;
}

.notify.ssi-error {
  background-color: #bd3630;
}

.notify.ssi-error .ssi-modalTitle span.error {
  background-position: 0px -168px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 33px;
  height: 33px;
}

.notify.ssi-error .ssi-modalContent::-webkit-scrollbar-thumb {
  background-color: #9d1d2a;
}

.notify.ssi-error:hover {
  background-color: #a73832;
}

/*------------------------End of notify plugin------------------------------*/
